import { Navigate } from 'react-router-dom';
import { IAuthRoute } from './Interface/IAuthRoute';
import { useEffect } from 'react';
// function checkRole(role){
//     if(localStorage.getItem("token")!==null){
//         let userData = JSON.parse(atob(localStorage.getItem("data")))
//         if(role === "seller" && userData.seller_info){      
//             return true;
//         }else if(role === "user" && userData){
//             return true;
//         }else if(role === "admin" && userData.isAdmin){
//             return true;
//         }else if(role === "reseller" && userData.reseller){      
//             return true;
//         }else{
//             return false;
//         }
//     }else{
//         return "home";
//     }  
// }
export const AuthRoute = ({ Component }: IAuthRoute) => {
    const HasToken = localStorage.getItem("token") ? true : false;

    useEffect(() =>{
    
    },[])

    function render(c: JSX.Element) {
        return c;
    }

    return (
        !HasToken ? <Navigate to="/" /> : render(Component)
    )
}

export default AuthRoute;
  
